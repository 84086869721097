import { withAuthenticationRequired } from '@auth0/auth0-react'
import { navigate, PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import MobileNavbar from '../../../components/navbar/mobile'
import Navbar from '../../../components/navbar'
import { useCart } from '../../../hooks/useCart'
import RegistrationReview from '../../../components/register/review/review'

const RegisterTeamReviewPage = (props: PageProps) => {
  const cartType = 'team'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  const { cart, isCartLoading } = useCart({ type: cartType })

  useEffect(() => {
    if (!isCartLoading && !cart?.data?.competition) {
      navigate(`/register/team/venue`)
    }
  }, [cart?.data?.competition, isCartLoading])

  useEffect(() => {
    if (!isCartLoading && !cart?.data?.teamDetails) {
      navigate(`/register/team/details`)
    }
  }, [cart?.data?.teamDetails, isCartLoading])

  useEffect(() => {
    if (!isCartLoading && !cart?.data?.teamUniformDesign) {
      navigate(`/register/team/uniform-design`)
    }
  }, [cart?.data?.teamDetails, isCartLoading])

  const onSuccess = () => {
    navigate('/register/team/invite')
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <RegistrationReview onSuccess={onSuccess} cartType={cartType} />
    </>
  )
}

export default withAuthenticationRequired(RegisterTeamReviewPage)
